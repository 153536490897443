<template>
    <the-snapshot-bundle-dialog :bundleData="bundleData" :defaultPanel="1" @hook:mounted="hasMounted = true">
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                    <v-btn fab color="primary" class="my-2 grading-tour"><v-icon>grading</v-icon></v-btn>
                </v-list-item>
            </template>
            <span>Assignment Grading</span>
        </v-tooltip>
    </the-snapshot-bundle-dialog>
</template>

<script>
import Shepherd from 'shepherd.js'
import { mapGetters, mapState } from 'vuex'
const TheSnapshotBundleDialog = () => import('./TheSnapshotBundleContentDialog')

export default {
    components: { TheSnapshotBundleDialog },
    computed: {
        ...mapState('spaceStore', ['spaceBundles']),
        ...mapGetters('spaceStore', ['isSpaceAdmin']),
        bundleData() {
            return this.spaceBundles.find(b => b.bid.toString() === this.$route.query.grading.toString())
        },
        showHelper() {
            return this.bundleData && this.hasMounted && this.$route.name === 'app-open'
        }
    },
    data() {
        return {
            hasMounted: false
        }
    },
    watch: {
        showHelper: {
            handler: function (to) {
                if (to) {
                    this.$nextTick(() => {
                        const tour = new Shepherd.Tour({
                            defaultStepOptions: {
                                cancelIcon: {
                                    enabled: true
                                },
                                classes: 'secondary--text',
                                scrollTo: { behavior: 'smooth' }
                            }
                        })

                        tour.addStep({
                            title: 'Entering a Grade',
                            text: `Once you are done reviewing the assignment,\
  just click here to enter the grade!`,
                            attachTo: {
                                element: '.grading-tour',
                                on: 'right'
                            },
                            id: 'open-grading'
                        })
                        tour.start()
                    })
                }
            },
            immediate: true
        }
    }
}
</script>
